import React, { useEffect, useRef, useState } from 'react';
import { useParallax } from 'react-scroll-parallax';

import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { AssetsAppName, getWebAssetsUrl } from '@utils/web-assets';

function WorkflowCard3Image() {
  const [disabled, setDisabled] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(containerRef, { threshold: 1 });

  const unitRef = useParallax<HTMLImageElement>({
    speed: -20,
    translateY: [50, -50],
    scale: [1, 1],
    easing: 'easeIn',
    disabled: disabled,
  });
  const starRef = useParallax<HTMLImageElement>({
    speed: -20,
    translateY: [30, 0],
    scale: [1, 1],
    easing: 'easeIn',
    disabled: disabled,
  });
  const boxRef = useParallax<HTMLImageElement>({
    speed: -30,
    translateY: [30, -10],
    scale: [1, 1],
    easing: 'easeIn',
    disabled: disabled,
  });

  useEffect(() => {
    if (entry?.isIntersecting) {
      // setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [entry]);

  return (
    <div
      className={ivclass(
        'iv-relative',
        'iv-w-full xl:iv-h-[560px] lg:iv-h-[480px] md:iv-h-[380px] sm:iv-h-[280px] iv-max-h-[560px]',
      )}
      ref={containerRef}
    >
      <ImageComponent
        elementype="native"
        className={ivclass(
          'iv-absolute',
          'iv-bottom-[50px] iv-right-0 md:iv-right-[-40px] sm:iv-right-[-40px] iv-object-contain',
          'iv-z-10',
          'iv-w-auto iv-h-auto',
          'lazyload',
        )}
        data-src={getWebAssetsUrl(
          '/homepage/workflow/unit.png',
          AssetsAppName.LANDING_PAGES,
        )}
        ref={unitRef.ref}
        alt="Media"
      />
      <ImageComponent
        elementype="native"
        className={ivclass(
          'iv-absolute',
          'iv-bottom-0 iv-left-[120px] md:iv-left-[-20px] sm:iv-left-0 md:iv-max-h-[220px] sm:iv-max-h-[170px]',
          'iv-z-[8]',
          'iv-w-auto iv-h-auto',
          'lazyload',
        )}
        data-src={getWebAssetsUrl(
          '/homepage/workflow/star.png',
          AssetsAppName.LANDING_PAGES,
        )}
        ref={starRef.ref}
        alt="Media"
      />
      <ImageComponent
        elementype="native"
        className={ivclass(
          'iv-absolute',
          'iv-top-[10px] iv-right-[170px] md:iv-right-[10px] sm:iv-right-0 sm:iv-top-[-40px]',
          'iv-z-[9]',
          'iv-w-auto iv-h-auto',
          'lazyload',
        )}
        data-src={getWebAssetsUrl(
          '/homepage/workflow/box.png',
          AssetsAppName.LANDING_PAGES,
        )}
        ref={boxRef.ref}
        alt="Media"
      />
    </div>
  );
}

export default WorkflowCard3Image;
