import React from 'react';
import { useParallax } from 'react-scroll-parallax';

import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { AssetsAppName, getWebAssetsUrl } from '@utils/web-assets';

function WorkflowCard2Image() {
  const disabled = false;
  const blueRef = useParallax<HTMLImageElement>({
    speed: -10,
    translateY: [0, -30],
    scale: [1, 1],
    disabled,
  });
  const pinkRef = useParallax<HTMLImageElement>({
    speed: -20,
    translateY: [0, -30],
    scale: [1, 1],
    disabled,
  });
  const shoeRef = useParallax<HTMLImageElement>({
    speed: -5,
    translateY: [50, -30],
    scale: [1, 1],
    disabled,
  });
  const brandRef = useParallax<HTMLImageElement>({
    speed: -20,
    translateY: [30, -30],
    scale: [1, 1],
    disabled,
  });
  const thumbRef = useParallax<HTMLImageElement>({
    speed: -20,
    translateY: [30, -30],
    scale: [1, 1],
    disabled,
  });
  const traspRef = useParallax<HTMLImageElement>({
    speed: -20,
    translateY: [30, -30],
    scale: [1, 1],
    disabled,
  });

  const brandLogoDesktop = getWebAssetsUrl(
    '/homepage/workflow/brand.png',
    AssetsAppName.LANDING_PAGES,
  );

  const brandLogoMobile = getWebAssetsUrl(
    '/homepage/workflow/brand-mobile.png',
    AssetsAppName.LANDING_PAGES,
  );

  const brandLogoSrcSet = `${brandLogoMobile} 90w, ${brandLogoDesktop} 235w`;

  const brandLogoSizes = `(max-width: 500px) 10vw, 235px`;
  return (
    <div
      className={ivclass(
        'iv-relative',
        'iv-w-full xl:iv-h-[560px] lg:iv-h-[480px] md:iv-h-[380px] sm:iv-h-[280px] iv-max-h-[560px]',
      )}
    >
      <ImageComponent
        elementype="native"
        className={ivclass(
          'iv-absolute',
          'iv-right-0 md:iv-right-[-150px] sm:iv-right-[-140px] iv-bottom-[-140px] md:iv-bottom-[-200px]',
          'iv-z-[1]',
          'lazyload',
        )}
        src={getWebAssetsUrl(
          '/homepage/workflow/rectangle-blue.webp',
          AssetsAppName.LANDING_PAGES,
        )}
        ref={blueRef.ref}
        alt="Media"
      />
      <ImageComponent
        elementype="native"
        className={ivclass(
          'iv-absolute',
          'iv-right-1/4 md:iv-right-[-80px] sm:iv-right-[-15%] iv-bottom-[-150px] sm:iv-bottom-[-120px]',
          'iv-z-[2]',
          'lazyload',
        )}
        src={getWebAssetsUrl(
          '/homepage/workflow/rectangle-pink.webp',
          AssetsAppName.LANDING_PAGES,
        )}
        ref={pinkRef.ref}
        alt="Media"
      />
      <ImageComponent
        elementype="native"
        className={ivclass(
          'iv-absolute',
          'iv-top-0 iv-right-0 sm:iv-left-0 sm:iv-right-full',
          'iv-z-10 sm:iv-w-[340px]',
          'lazyload',
        )}
        data-src={getWebAssetsUrl(
          '/homepage/workflow/shoe.png',
          AssetsAppName.LANDING_PAGES,
        )}
        ref={shoeRef.ref}
        alt="Media"
      />
      <ImageComponent
        elementype="native"
        className={ivclass(
          'iv-absolute',
          'iv-bottom-[50px] iv-left-[60px] md:iv-left-[40px] sm:iv-left-[20px]',
          'iv-z-[5] md:iv-w-[60px] sm:iv-w-[45px]',
          'lazyload',
        )}
        data-src={getWebAssetsUrl(
          '/homepage/workflow/thumb.png',
          AssetsAppName.LANDING_PAGES,
        )}
        ref={thumbRef.ref}
        alt="Media"
      />
      <ImageComponent
        elementype="native"
        className={ivclass(
          'iv-absolute',
          'iv-left-[140px] md:iv-left-[20px] sm:iv-left-[40px] iv-top-[140px] md:iv-top-[100px] sm:iv-top-[70px]',
          'iv-z-[11] md:iv-w-[120px] sm:iv-w-[80px]',
          'lazyload',
        )}
        srcSet={brandLogoSrcSet}
        sizes={brandLogoSizes}
        src={brandLogoDesktop}
        loading="lazy"
        ref={brandRef.ref}
        alt="Media"
      />
      <ImageComponent
        elementype="native"
        className={ivclass(
          'iv-absolute',
          'iv-top-[100px] iv-right-0 sm:iv-top-[50px]',
          'iv-z-[4]',
          'lazyload',
        )}
        src={getWebAssetsUrl(
          '/homepage/workflow/transp-1.webp',
          AssetsAppName.LANDING_PAGES,
        )}
        loading="lazy"
        ref={traspRef.ref}
        alt="Media"
      />
    </div>
  );
}

export default WorkflowCard2Image;
