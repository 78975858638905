import ivclass from '@invideoio/web-shared/Modules/IVClass';
import React from 'react';

import WorkFlowCard from './MakeWorkFlowCard';
import { BrowserProps, MakeWorkflow, PosterType } from '../../types';
import Title from '@components/common/Title/TitleH2BlockGradient';
import { getPosterURL } from '@invideoio/web-shared/utils/common';
import ParallaxImageCard from './ParallaxImageCard';
import WorkflowCard3Image from './HomePageWorkflow/WorkflowCard3Image';
import WorkflowCard2Image from './HomePageWorkflow/WorkflowCard2Image';

interface HomeWorkflowProps extends BrowserProps {
  platform: string;
  makeWorkFlow: MakeWorkflow;
}

function HomePageWorkflow(props: HomeWorkflowProps) {
  const { platform, browser, makeWorkFlow } = props;

  const { title, MakeWorkflowCard } = makeWorkFlow;

  return (
    <section
      className={ivclass(
        'xl:iv-px-20 lg:iv-px-40 md:iv-px-25 sm:iv-px-20',
        'xl:iv-pt-200 iv-pt-150 sm:iv-pt-100',
        'iv-grid iv-grid-cols-12 md:iv-grid-cols-8 sm:iv-grid-cols-4',
      )}
    >
      <div
        className={ivclass(
          'iv-grid',
          'sm:iv-px-5',
          'iv-text-center',
          'iv-col-span-12 iv-col-start-2 iv-col-end-12 lg:iv-col-start-3 lg:iv-col-end-11',
          'md:iv-col-span-8 md:iv-col-start-1',
          'sm:iv-col-span-4 sm:iv-col-start-1',
        )}
      >
        <Title heading={title} spanClass="sm:iv-block" />
      </div>

      <div
        className={ivclass(
          'iv-col-span-12',
          'xl:iv-pt-75 iv-pt-50 sm:iv-pt-30',
        )}
      >
        <div
          className={ivclass('iv-mb-40 sm:iv-mb-20 last-of-type:iv-mb-[0px]')}
        >
          <div
            className={ivclass(
              'iv-grid',
              'iv-gap-40 md:iv-gap-25 sm:iv-gap-25',
              'iv-grid-cols-1',
            )}
          >
            {Array.isArray(MakeWorkflowCard)
              ? MakeWorkflowCard.map((card, index) => {
                  const { Image } = card;
                  const videPoster: PosterType = Image.desktop || {
                    defaultURL: Image.url,
                  };

                  const posterURL = getPosterURL(videPoster, browser);

                  const ImageContainers: Record<number, JSX.Element> = {
                    0: (
                      <ParallaxImageCard
                        imageUrl={posterURL}
                        className="iv-h-full iv-object-cover iv-object-left iv-aspect-[1.035] lazyload"
                      />
                    ),
                    1: <WorkflowCard2Image />,
                    2: <WorkflowCard3Image />,
                  };

                  return (
                    <WorkFlowCard
                      makeWorkFlowCard={card}
                      key={card.id}
                      browser={browser}
                      customImageContainer={ImageContainers[index]}
                    />
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomePageWorkflow;
