import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import React, { ImgHTMLAttributes } from 'react';
import { useParallax } from 'react-scroll-parallax';

interface ImageProps
  extends React.PropsWithChildren<ImgHTMLAttributes<HTMLImageElement>> {
  imageUrl?: string;
}

function ParallaxImageCard(props: ImageProps) {
  const { imageUrl, className, ...imageProps } = props;
  const imageRef = useParallax<HTMLImageElement>({
    speed: -10,
    translateY: [-30, 30],
    scale: [1.2, 1.2],
  });

  return (
    <ImageComponent
      elementype="native"
      className={className}
      data-src={imageUrl}
      ref={imageRef.ref}
      alt="Media"
      {...imageProps}
    />
  );
}

export default ParallaxImageCard;
